.pricing-table .plan .price {
    font-size: 2em;
    font-weight: bold;
}

.flex {
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
}

.flex-center-self {
    align-self: center;
    -webkit-align-self: center;
    -ms-align-self: center;
    align-self: center;
}

img.product-picture-header {
    max-height: 600px;
    /*max-width: 555px;*/
}

.img-thumbnail.product-picture-header{
    max-height: 608px;
    max-width: 563px;
    margin-right: 1em;
}

.products.product-thumb-info-list {
    list-style: none;
}

.product-thumb-info-list li{
    margin-bottom: 1em;
}

.product-thumb-info-content h4 {
    min-height: 2.5em;
}

.product-thumb-info-content h3 {
    margin-bottom: 10px;
}

.product-thumb-info-content ins {
    font-size: 1.1em;
    font-weight: bold;
}
ul.products.product-thumb-info-list {
  padding: 5px;
}

.product-thumb-info-content h3.heading-primary {
    font-size: 1.5em;
}
.product-thumb-info-content span.price {
    font-size: 1.3em;
}

.price small {
    font-size: 0.45em;
}

.shop .star-rating span.stars-0:before {
    content: '';
}
.shop .star-rating span.stars-5:before {
    content: 's';
}
.shop .star-rating span.stars-10:before {
    content: 'S';
}
.shop .star-rating span.stars-15:before {
    content: 'Ss';
}
.shop .star-rating span.stars-20:before {
    content: 'SS';
}
.shop .star-rating span.stars-25:before {
    content: 'SSs';
}
.shop .star-rating span.stars-30:before {
    content: 'SSS';
}
.shop .star-rating span.stars-35:before {
    content: 'SSSs';
}
.shop .star-rating span.stars-40:before {
    content: 'SSSS';
}
.shop .star-rating span.stars-45:before {
    content: 'SSSSs';
}
.shop .star-rating span.stars-50:before {
    content: 'SSSSS';
}
.header-logo a:hover {
    text-decoration: none;
}
.header-logo a .word-logo {
    font-size: 2em;
    font-weight: bold;
    text-decoration: underline;
}
.header-logo a small {
    font-size: 1em;
}

.toc {
    background-color: #f9f9f9;
    border: 1px solid #aaa;
    font-size: 95%;
    margin: 0 1em 1em;
    width: 100%;
}

.toc ul {
    list-style: none;
}
.toc ul {
    padding: 1em 0.8em;
}

.toc ul.menu_level_1 {
    padding: 0em 0em 0em 1.5em;
}

.toc ul.menu_level_2 {
    padding: 0em 0em 0em 2.5em;
}

.toc .toctitle {
    color: #000000;
}

.img-caption {
    font-family: caption;
    font-size: 0.9em;
    font-style: italic;
}
.caption {
    margin: 0.5em;
}

.brand-img {
    min-height: 250px;
    max-height: 250px;
}